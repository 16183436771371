export const artifactData = [
    {
        id: "1",
        name: "Antler",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/1.gltf",
        preview: "/cosmetic/preview/artifact/1.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "2",
        name: "Backcap",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/2.gltf",
        preview: "/cosmetic/preview/artifact/2.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "3",
        name: "Small Crown",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/3.gltf",
        preview: "/cosmetic/preview/artifact/3.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "4",
        name: "Halo",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/4.gltf",
        preview: "/cosmetic/preview/artifact/4.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "5",
        name: "Koala Hat",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/5.gltf",
        preview: "/cosmetic/preview/artifact/5.webp",
        obtain: [],
    },
    {
        id: "6",
        name: "Classic Moustache",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/6.gltf",
        preview: "/cosmetic/preview/artifact/6.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "7",
        name: "Question Mark",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/7.gltf",
        preview: "/cosmetic/preview/artifact/7.webp",
        obtain: [],
    },
    {
        id: "8",
        name: "Rabbit Ears",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/8.gltf",
        preview: "/cosmetic/preview/artifact/8.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "9",
        name: "Thunder Cloud",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/9.gltf",
        preview: "/cosmetic/preview/artifact/9.webp",
        obtain: [],
    },
    {
        id: "10",
        name: "UFO",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/10.gltf",
        preview: "/cosmetic/preview/artifact/10.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "11",
        name: "Adidas Headband",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/11.gltf",
        preview: "/cosmetic/preview/artifact/11.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "12",
        name: "Nike Headband",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/12.gltf",
        preview: "/cosmetic/preview/artifact/12.webp",
        obtain: [
            "Vip Rank",
            "Booster Rank",
            "Media Rank",
            "Media+ Rank",
            "Mvp Rank",
            "Mvp+ Rank",
        ],
    },
    {
        id: "13",
        name: "Louis V. Headband",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/13.gltf",
        preview: "/cosmetic/preview/artifact/13.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "14",
        name: "Santa Hat",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/14.gltf",
        preview: "/cosmetic/preview/artifact/14.webp",
        obtain: [],
    },
    {
        id: "15",
        name: "Witch Hat",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/15.gltf",
        preview: "/cosmetic/preview/artifact/15.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "16",
        name: "Mini Angel Wing",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/16.gltf",
        preview: "/cosmetic/preview/artifact/16.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "17",
        name: "SWAT Shield",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/17.gltf",
        preview: "/cosmetic/preview/artifact/17.webp",
        obtain: ["Mvp Rank", "Mvp+ Rank"],
    },
    {
        id: "18",
        name: "Katana Set",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/18.gltf",
        preview: "/cosmetic/preview/artifact/18.webp",
        obtain: ["Angel Rising Crate", "Guarantee Epic Crate"],
    },
    {
        id: "19",
        name: "Blaze Rod",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/19.gltf",
        preview: "/cosmetic/preview/artifact/19.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "20",
        name: "Blue Susanoo",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/20.gltf",
        preview: "/cosmetic/preview/artifact/20.webp",
        obtain: ["Season 1 Zeqa Pass"],
    },
    {
        id: "21",
        name: "Angel Wing",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/21.gltf",
        preview: "/cosmetic/preview/artifact/21.webp",
        obtain: ["Angel Rising Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "22",
        name: "Red Kagune",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/22.gltf",
        preview: "/cosmetic/preview/artifact/22.webp",
        obtain: ["Season 1 Zeqa Pass"],
    },
    {
        id: "23",
        name: "Blue Lightsaber",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/23.gltf",
        preview: "/cosmetic/preview/artifact/23.webp",
        obtain: [],
    },
    {
        id: "24",
        name: "Axe On Head",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/24.gltf",
        preview: "/cosmetic/preview/artifact/24.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "25",
        name: "Leather Backpack",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/25.gltf",
        preview: "/cosmetic/preview/artifact/25.webp",
        obtain: ["Angel Rising Crate", "Guarantee Epic Crate"],
    },
    {
        id: "26",
        name: "Holding Banana",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/26.gltf",
        preview: "/cosmetic/preview/artifact/26.webp",
        obtain: [],
    },
    {
        id: "27",
        name: "Cute Creeper",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/27.gltf",
        preview: "/cosmetic/preview/artifact/27.webp",
        obtain: ["Discord Invitation Event"],
    },
    {
        id: "28",
        name: "Goat Horn",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/28.gltf",
        preview: "/cosmetic/preview/artifact/28.webp",
        obtain: [],
    },
    {
        id: "29",
        name: "Gudoudame",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/29.gltf",
        preview: "/cosmetic/preview/artifact/29.webp",
        obtain: ["Staff Rank"],
    },
    {
        id: "30",
        name: "Super Mini Angel Wing",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/30.gltf",
        preview: "/cosmetic/preview/artifact/30.webp",
        obtain: ["Angel Rising Crate"],
    },
    {
        id: "31",
        name: "Angel Wing 2",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/31.gltf",
        preview: "/cosmetic/preview/artifact/31.webp",
        obtain: ["Twinging Star Crate"],
    },
    {
        id: "32",
        name: "Bald Headband",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/32.gltf",
        preview: "/cosmetic/preview/artifact/32.webp",
        obtain: [],
    },
    {
        id: "33",
        name: "Black Angel Set",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/33.gltf",
        preview: "/cosmetic/preview/artifact/33.webp",
        obtain: ["X-MAS QUEST 2022"],
    },
    {
        id: "34",
        name: "Blue Wing",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/34.gltf",
        preview: "/cosmetic/preview/artifact/34.webp",
        obtain: ["Twinging Star Crate"],
    },
    {
        id: "35",
        name: "Boxing Gloves",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/35.gltf",
        preview: "/cosmetic/preview/artifact/35.webp",
        obtain: [],
    },
    {
        id: "36",
        name: "Bubble",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/36.gltf",
        preview: "/cosmetic/preview/artifact/36.webp",
        obtain: [],
    },
    {
        id: "37",
        name: "King's Crown",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/37.gltf",
        preview: "/cosmetic/preview/artifact/37.webp",
        obtain: ["Season 5 Login Reward"],
    },
    {
        id: "38",
        name: "Devil Wing",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/38.gltf",
        preview: "/cosmetic/preview/artifact/38.webp",
        obtain: ["Twinging Star Crate"],
    },
    {
        id: "39",
        name: "Dollar",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/39.gltf",
        preview: "/cosmetic/preview/artifact/39.webp",
        obtain: [],
    },
    {
        id: "40",
        name: "Dragon Wing",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/40.gltf",
        preview: "/cosmetic/preview/artifact/40.webp",
        obtain: ["Twinging Star Crate"],
    },
    {
        id: "41",
        name: "Ender Dragon Tail",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/41.gltf",
        preview: "/cosmetic/preview/artifact/41.webp",
        obtain: [],
    },
    {
        id: "42",
        name: "Ender Wing",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/42.gltf",
        preview: "/cosmetic/preview/artifact/42.webp",
        obtain: ["Twinging Star Crate"],
    },
    {
        id: "43",
        name: "Fox",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/43.gltf",
        preview: "/cosmetic/preview/artifact/43.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "44",
        name: "Sun Glasses",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/44.gltf",
        preview: "/cosmetic/preview/artifact/44.webp",
        obtain: [],
    },
    {
        id: "45",
        name: "Headphone",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/45.gltf",
        preview: "/cosmetic/preview/artifact/45.webp",
        obtain: [],
    },
    {
        id: "46",
        name: "Headphone & Notes",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/46.gltf",
        preview: "/cosmetic/preview/artifact/46.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "47",
        name: "No.1 MLG RUSH",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/47.gltf",
        preview: "/cosmetic/preview/artifact/47.webp",
        obtain: [],
    },
    {
        id: "48",
        name: "Necktie",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/48.gltf",
        preview: "/cosmetic/preview/artifact/48.webp",
        obtain: [],
    },
    {
        id: "49",
        name: "Phantom Wing",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/49.gltf",
        preview: "/cosmetic/preview/artifact/49.webp",
        obtain: ["Twinging Star Crate"],
    },
    {
        id: "50",
        name: "Rabbit",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/50.gltf",
        preview: "/cosmetic/preview/artifact/50.webp",
        obtain: [],
    },
    {
        id: "51",
        name: "Red Wing",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/51.gltf",
        preview: "/cosmetic/preview/artifact/51.webp",
        obtain: [],
    },
    {
        id: "52",
        name: "Rich Headband",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/52.gltf",
        preview: "/cosmetic/preview/artifact/52.webp",
        obtain: [],
    },
    {
        id: "53",
        name: "Sickle",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/53.gltf",
        preview: "/cosmetic/preview/artifact/53.webp",
        obtain: [],
    },
    {
        id: "54",
        name: "Viking",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/54.gltf",
        preview: "/cosmetic/preview/artifact/54.webp",
        obtain: [],
    },
    {
        id: "55",
        name: "Wave Bandanna",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/55.gltf",
        preview: "/cosmetic/preview/artifact/55.webp",
        obtain: [],
    },
    {
        id: "56",
        name: "White Heart",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/56.gltf",
        preview: "/cosmetic/preview/artifact/56.webp",
        obtain: [],
    },
    {
        id: "57",
        name: "Wither Head",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/57.gltf",
        preview: "/cosmetic/preview/artifact/57.webp",
        obtain: ["Halloween Event 2023"],
    },
    {
        id: "58",
        name: "Purple Zeqa Bandanna",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/58.gltf",
        preview: "/cosmetic/preview/artifact/58.webp",
        obtain: [],
    },
    {
        id: "59",
        name: "Katana Wing",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/59.gltf",
        preview: "/cosmetic/preview/artifact/59.webp",
        obtain: ["Sun Land Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "60",
        name: "Raijin Drums",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/60.gltf",
        preview: "/cosmetic/preview/artifact/60.webp",
        obtain: ["Sun Land Crate", "Guarantee Epic Crate"],
    },
    {
        id: "61",
        name: "Shirasaya Flag",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/61.gltf",
        preview: "/cosmetic/preview/artifact/61.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "62",
        name: "White Karate Gi",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/62.gltf",
        preview: "/cosmetic/preview/artifact/62.webp",
        obtain: ["Sun Land Crate", "Guarantee Epic Crate"],
    },
    {
        id: "63",
        name: "Candy Cane",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/63.gltf",
        preview: "/cosmetic/preview/artifact/63.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "64",
        name: "Green Yukata",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/64.gltf",
        preview: "/cosmetic/preview/artifact/64.webp",
        obtain: ["Sun Land Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "65",
        name: "Pink Kimono",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/65.gltf",
        preview: "/cosmetic/preview/artifact/65.webp",
        obtain: ["Sun Land Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "66",
        name: "Black Karate Gi",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/66.gltf",
        preview: "/cosmetic/preview/artifact/66.webp",
        obtain: ["Sun Land Crate", "Guarantee Epic Crate"],
    },
    {
        id: "67",
        name: "Japan Headband",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/67.gltf",
        preview: "/cosmetic/preview/artifact/67.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "68",
        name: "Tatakae",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/68.gltf",
        preview: "/cosmetic/preview/artifact/68.webp",
        obtain: ["Sun Land Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "69",
        name: "Waist Lightsaber",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/69.gltf",
        preview: "/cosmetic/preview/artifact/69.webp",
        obtain: ["Sun Land Crate"],
    },
    {
        id: "70",
        name: "Sumo Suit",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/70.gltf",
        preview: "/cosmetic/preview/artifact/70.webp",
        obtain: ["Meme Costume Crate"],
    },
    {
        id: "71",
        name: "Cat Mask",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/71.gltf",
        preview: "/cosmetic/preview/artifact/71.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "72",
        name: "Dog Mask",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/72.gltf",
        preview: "/cosmetic/preview/artifact/72.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "73",
        name: "Little Doge",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/73.gltf",
        preview: "/cosmetic/preview/artifact/73.webp",
        obtain: ["Meme Time Crate"],
    },
    {
        id: "74",
        name: "Little Frog",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/74.gltf",
        preview: "/cosmetic/preview/artifact/74.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "75",
        name: "Lean Pack",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/75.gltf",
        preview: "/cosmetic/preview/artifact/75.webp",
        obtain: ["Meme Time Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "76",
        name: "Meme Glasses",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/76.gltf",
        preview: "/cosmetic/preview/artifact/76.webp",
        obtain: [],
    },
    {
        id: "77",
        name: "Menacing",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/77.gltf",
        preview: "/cosmetic/preview/artifact/77.webp",
        obtain: ["Meme Time Crate", "Guarantee Epic Crate"],
    },
    {
        id: "78",
        name: "MLG Set",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/78.gltf",
        preview: "/cosmetic/preview/artifact/78.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "79",
        name: "Mushroom Cap",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/79.gltf",
        preview: "/cosmetic/preview/artifact/79.webp",
        obtain: ["Season 2 Elo Leaderboard (#1-3) Rewards", "Store Lottery"],
    },
    {
        id: "80",
        name: "Noob Mask",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/80.gltf",
        preview: "/cosmetic/preview/artifact/80.webp",
        obtain: ["Meme Time Crate"],
    },
    {
        id: "81",
        name: "Pig Mask",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/81.gltf",
        preview: "/cosmetic/preview/artifact/81.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "82",
        name: "Pushin P",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/82.gltf",
        preview: "/cosmetic/preview/artifact/82.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "83",
        name: "Virus Claws",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/83.gltf",
        preview: "/cosmetic/preview/artifact/83.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "84",
        name: "Keyboard Blade",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/84.gltf",
        preview: "/cosmetic/preview/artifact/84.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "85",
        name: "Hacker Jetpack",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/85.gltf",
        preview: "/cosmetic/preview/artifact/85.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "86",
        name: "Cyborg Suit",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/86.gltf",
        preview: "/cosmetic/preview/artifact/86.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "87",
        name: "Tech Wing",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/87.gltf",
        preview: "/cosmetic/preview/artifact/87.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "88",
        name: "Sky Island",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/88.gltf",
        preview: "/cosmetic/preview/artifact/88.webp",
        obtain: ["Season 3 Elo Leaderboard (#1-3) Rewards", "Store Lottery"],
    },
    {
        id: "89",
        name: "Muramasa",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/89.gltf",
        preview: "/cosmetic/preview/artifact/89.webp",
        obtain: [],
    },
    {
        id: "90",
        name: "Fire Wing",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/90.gltf",
        preview: "/cosmetic/preview/artifact/90.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "91",
        name: "10K DISCORD ARTIFACT",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/91.gltf",
        preview: "/cosmetic/preview/artifact/91.webp",
        obtain: ["Season 4 Login Reward"],
    },
    {
        id: "92",
        name: "10K Cute Wumpus",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/92.gltf",
        preview: "/cosmetic/preview/artifact/92.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "93",
        name: "Burger Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/93.gltf",
        preview: "/cosmetic/preview/artifact/93.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "94",
        name: "Hotdog Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/94.gltf",
        preview: "/cosmetic/preview/artifact/94.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "95",
        name: "Pineapple Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/95.gltf",
        preview: "/cosmetic/preview/artifact/95.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "96",
        name: "Classic Spaceman",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/96.gltf",
        preview: "/cosmetic/preview/artifact/96.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "97",
        name: "Golden Space Helmet",
        type: "artifact",
        rarity: "common",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/97.gltf",
        preview: "/cosmetic/preview/artifact/97.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "98",
        name: "Infinity Gauntlet",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/98.gltf",
        preview: "/cosmetic/preview/artifact/98.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "99",
        name: "Mini Milky Way",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/99.gltf",
        preview: "/cosmetic/preview/artifact/99.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "100",
        name: "To Infinity And Beyond",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/100.gltf",
        preview: "/cosmetic/preview/artifact/100.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "101",
        name: "Stardust Wing",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/101.gltf",
        preview: "/cosmetic/preview/artifact/101.webp",
        obtain: ["Season 5 Elo Leaderboard (#1-3) Rewards", "Store Lottery"],
    },
    {
        id: "102",
        name: "Blue Space Suit",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/102.gltf",
        preview: "/cosmetic/preview/artifact/102.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "103",
        name: "Red Space Suit",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/103.gltf",
        preview: "/cosmetic/preview/artifact/103.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "104",
        name: "Crocodile Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/104.gltf",
        preview: "/cosmetic/preview/artifact/104.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "105",
        name: "Nexus Wing",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/105.gltf",
        preview: "/cosmetic/preview/artifact/105.webp",
        obtain: ["Chr7st Collaboration"],
    },
    {
        id: "106",
        name: "Earth Aura",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/106.gltf",
        preview: "/cosmetic/preview/artifact/106.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "107",
        name: "Earth Hand",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/107.gltf",
        preview: "/cosmetic/preview/artifact/107.webp",
        obtain: [],
    },
    {
        id: "108",
        name: "Fire Aura",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/108.gltf",
        preview: "/cosmetic/preview/artifact/108.webp",
        obtain: [],
    },
    {
        id: "109",
        name: "Fire Crown",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/109.gltf",
        preview: "/cosmetic/preview/artifact/109.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "110",
        name: "Ice Aura",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/110.gltf",
        preview: "/cosmetic/preview/artifact/110.webp",
        obtain: [],
    },
    {
        id: "111",
        name: "Lightning Aura",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/111.gltf",
        preview: "/cosmetic/preview/artifact/111.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "112",
        name: "Anchor Wings",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/112.gltf",
        preview: "/cosmetic/preview/artifact/112.webp",
        obtain: ["Out Of The Sea Crate"],
    },
    {
        id: "113",
        name: "Bird",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/113.gltf",
        preview: "/cosmetic/preview/artifact/113.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "114",
        name: "Golden Skull",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/114.gltf",
        preview: "/cosmetic/preview/artifact/114.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "115",
        name: "Treasure Chest",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/115.gltf",
        preview: "/cosmetic/preview/artifact/115.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "116",
        name: "Pirate Guy",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/116.gltf",
        preview: "/cosmetic/preview/artifact/116.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "117",
        name: "Pirate Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/117.gltf",
        preview: "/cosmetic/preview/artifact/117.webp",
        obtain: ["Season 7 Login Reward"],
    },
    {
        id: "118",
        name: "Pirate Sword",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/118.gltf",
        preview: "/cosmetic/preview/artifact/118.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "119",
        name: "Pirate Flag",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/119.gltf",
        preview: "/cosmetic/preview/artifact/119.webp",
        obtain: ["Out Of The Sea Crate"],
    },
    {
        id: "120",
        name: "Jack-o'-lantern",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/120.gltf",
        preview: "/cosmetic/preview/artifact/120.webp",
        obtain: ["Halloween Event 2023"],
    },
    {
        id: "121",
        name: "Bat Wings",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/121.gltf",
        preview: "/cosmetic/preview/artifact/121.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "122",
        name: "Cauldron Cap",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/122.gltf",
        preview: "/cosmetic/preview/artifact/122.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "123",
        name: "Alien Abuction",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/123.gltf",
        preview: "/cosmetic/preview/artifact/123.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "124",
        name: "Melon Madness",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/124.gltf",
        preview: "/cosmetic/preview/artifact/124.webp",
        obtain: ["Scott"],
    },
    {
        id: "125",
        name: "Cherry Wings",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/125.gltf",
        preview: "/cosmetic/preview/artifact/125.webp",
        obtain: ["Autumn Bundle 2023"],
    },
    {
        id: "126",
        name: "Maple Wings",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/126.gltf",
        preview: "/cosmetic/preview/artifact/126.webp",
        obtain: ["Autumn Bundle 2023"],
    },
    {
        id: "127",
        name: "Captain America Shield",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/127.gltf",
        preview: "/cosmetic/preview/artifact/127.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "128",
        name: "Mario Cap",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/128.gltf",
        preview: "/cosmetic/preview/artifact/128.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "129",
        name: "Mickey Mouse",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/129.gltf",
        preview: "/cosmetic/preview/artifact/129.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "130",
        name: "Shyguy Mask",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/130.gltf",
        preview: "/cosmetic/preview/artifact/130.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "153",
        name: "Heart Wings",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/153.gltf",
        preview: "/cosmetic/preview/artifact/153.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "154",
        name: "Love Byte",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/154.gltf",
        preview: "/cosmetic/preview/artifact/154.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "155",
        name: "Heart Glasses",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/155.gltf",
        preview: "/cosmetic/preview/artifact/155.webp",
        obtain: ["Valentines Crate 2024"],
    },
        {
        id: "156",
        name: "Valentines Confetti",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/156.gltf",
        preview: "/cosmetic/preview/artifact/156.webp",
        obtain: ["Valentines Crate 2024"],
    },
        {
        id: "157",
        name: "Arrow Head",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/157.gltf",
        preview: "/cosmetic/preview/artifact/157.webp",
        obtain: ["Valentines Crate 2024"],
    },
        {
        id: "158",
        name: "Heart Headphones",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/158.gltf",
        preview: "/cosmetic/preview/artifact/158.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "145",
        name: "Candy Cane Sword",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/145.gltf",
        preview: "/cosmetic/preview/artifact/145.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "146",
        name: "Ice Crown",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/146.gltf",
        preview: "/cosmetic/preview/artifact/146.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "147",
        name: "Snowman Outfit",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/147.gltf",
        preview: "/cosmetic/preview/artifact/147.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "148",
        name: "Gingerbread Pet",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/148.gltf",
        preview: "/cosmetic/preview/artifact/148.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "149",
        name: "Frostfall",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/149.gltf",
        preview: "/cosmetic/preview/artifact/149.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "150",
        name: "Ski Backpack",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/150.gltf",
        preview: "/cosmetic/preview/artifact/150.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "151",
        name: "Present Head",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/151.gltf",
        preview: "/cosmetic/preview/artifact/151.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "152",
        name: "Frost Sword",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/152.gltf",
        preview: "/cosmetic/preview/artifact/152.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "159",
        name: "Clown Wig",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/159.gltf",
        preview: "/cosmetic/preview/artifact/159.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "160",
        name: "Balloons",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/160.gltf",
        preview: "/cosmetic/preview/artifact/160.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "161",
        name: "Flamingo Bouy",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/161.gltf",
        preview: "/cosmetic/preview/artifact/161.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "162",
        name: "Horse",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/162.gltf",
        preview: "/cosmetic/preview/artifact/162.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "163",
        name: "Pie Face",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/163.gltf",
        preview: "/cosmetic/preview/artifact/163.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "164",
        name: "Shutter Glasses",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/164.gltf",
        preview: "/cosmetic/preview/artifact/164.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "165",
        name: "Confetti Wings",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/165.gltf",
        preview: "/cosmetic/preview/artifact/165.webp",
        obtain: ["Discord 20k Celebration"],
    },
    {
        id: "166",
        name: "Carrot Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/166.gltf",
        preview: "/cosmetic/preview/artifact/166.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "167",
        name: "Easter Basket",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/167.gltf",
        preview: "/cosmetic/preview/artifact/167.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "168",
        name: "Bunny Merch",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/168.gltf",
        preview: "/cosmetic/preview/artifact/168.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "169",
        name: "Egg Warrior",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/169.gltf",
        preview: "/cosmetic/preview/artifact/169.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "170",
        name: "Bunny Hat",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/170.gltf",
        preview: "/cosmetic/preview/artifact/170.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "171",
        name: "Cherry Blossom Crown",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/171.gltf",
        preview: "/cosmetic/preview/artifact/171.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "172",
        name: "Butterfly Wings",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/172.gltf",
        preview: "/cosmetic/preview/artifact/172.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "173",
        name: "Chick",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/173.gltf",
        preview: "/cosmetic/preview/artifact/173.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "P1",
        name: "Gengar Headband",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P1.gltf",
        preview: "/cosmetic/preview/artifact/P1.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P2",
        name: "Piggy Aura",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P2.gltf",
        preview: "/cosmetic/preview/artifact/P2.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P3",
        name: "Piggy Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P3.gltf",
        preview: "/cosmetic/preview/artifact/P3.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P4",
        name: "Air Forces",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P4.gltf",
        preview: "/cosmetic/preview/artifact/P4.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P5",
        name: "Dave",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P5.gltf",
        preview: "/cosmetic/preview/artifact/P5.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P6",
        name: "Tubs Crown",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P6.gltf",
        preview: "/cosmetic/preview/artifact/P6.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P7",
        name: "GM19s Disguise",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P7.gltf",
        preview: "/cosmetic/preview/artifact/P7.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "P8",
        name: "WORST Player Headband",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/P8.gltf",
        preview: "/cosmetic/preview/artifact/P8.webp",
        obtain: ["store.zeqa.net"],
    },
    {
        id: "C1",
        name: "Hooded Man Costume",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C1.gltf",
        preview: "/cosmetic/preview/artifact/C1.webp",
        obtain: ["Exotic Madness Crate"],
    },
    {
        id: "C2",
        name: "Reaper Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/C2.gltf",
        preview: "/cosmetic/preview/artifact/C2.webp",
        obtain: ["Season 1 Zeqa Pass", "Halloween Event 2023"],
    },
    {
        id: "C3",
        name: "Banana Man Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C3.gltf",
        preview: "/cosmetic/preview/artifact/C3.webp",
        obtain: ["Season 1 Zeqa Pass"],
    },
    {
        id: "C4",
        name: "McDonald's Worker",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C4.gltf",
        preview: "/cosmetic/preview/artifact/C4.webp",
        obtain: ["Meme Costume Crate"],
    },
    {
        id: "C5",
        name: "Ducky Costume",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C5.gltf",
        preview: "/cosmetic/preview/artifact/C5.webp",
        obtain: ["Meme Costume Crate"],
    },
    {
        id: "C6",
        name: "Ghost Costume",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C6.gltf",
        preview: "/cosmetic/preview/artifact/C6.webp",
        obtain: ["Meme Costume Crate"],
    },
    {
        id: "C7",
        name: "Glitcher Costume",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C7.gltf",
        preview: "/cosmetic/preview/artifact/C7.webp",
        obtain: ["Meme Costume Crate"],
    },
    {
        id: "C8",
        name: "Jerry Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C8.gltf",
        preview: "/cosmetic/preview/artifact/C8.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "C9",
        name: "Pepe Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C9.gltf",
        preview: "/cosmetic/preview/artifact/C9.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "C10",
        name: "Pumpkin Man Costume",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C10.gltf",
        preview: "/cosmetic/preview/artifact/C10.webp",
        obtain: ["Meme Time Crate", "Guarantee Legendary Crate"],
    },
    {
        id: "C11",
        name: "Puppet Monkey Costume",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C11.gltf",
        preview: "/cosmetic/preview/artifact/C11.webp",
        obtain: ["Meme Time Crate", "Guarantee Epic Crate"],
    },
    {
        id: "C12",
        name: "Spooderman Costume",
        type: "artifact",
        rarity: "epic",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C12.gltf",
        preview: "/cosmetic/preview/artifact/C12.webp",
        obtain: ["Meme Costume Crate"],
    },
    {
        id: "C13",
        name: "Stonks Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C13.gltf",
        preview: "/cosmetic/preview/artifact/C13.webp",
        obtain: ["Season 2 Zeqa Pass"],
    },
    {
        id: "C14",
        name: "Shrek Costume",
        type: "artifact",
        rarity: "rare",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C14.gltf",
        preview: "/cosmetic/preview/artifact/C14.webp",
        obtain: ["Meme Time Crate"],
    },
    {
        id: "C15",
        name: "Robot Creeper Costume",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C15.gltf",
        preview: "/cosmetic/preview/artifact/C15.webp",
        obtain: ["Computer Geek Crate"],
    },
    {
        id: "C16",
        name: "Golden Totem Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C16.gltf",
        preview: "/cosmetic/preview/artifact/C16.webp",
        obtain: ["Season 3 Zeqa Pass"],
    },
    {
        id: "C17",
        name: "Wumpus King Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C17.gltf",
        preview: "/cosmetic/preview/artifact/C17.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "C18",
        name: "Fires King Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C18.gltf",
        preview: "/cosmetic/preview/artifact/C18.webp",
        obtain: ["Season 4 Zeqa Pass"],
    },
    {
        id: "C19",
        name: "The Mudge Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C19.gltf",
        preview: "/cosmetic/preview/artifact/C19.webp",
        obtain: ["Season 4 Elo Leaderboard (#1-3) Rewards", "Store Lottery"],
    },
    {
        id: "C20",
        name: "Alien Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C20.gltf",
        preview: "/cosmetic/preview/artifact/C20.webp",
        obtain: ["Season 6 Elo Leaderboard (#1-3) Rewards", "Store Lottery"],
    },
    {
        id: "C21",
        name: "Elite Astronaut Costume",
        type: "artifact",
        rarity: "legendary",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C21.gltf",
        preview: "/cosmetic/preview/artifact/C21.webp",
        obtain: ["Galactic Force Crate"],
    },
    {
        id: "C22",
        name: "Horseman Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/C22.gltf",
        preview: "/cosmetic/preview/artifact/C22.webp",
        obtain: ["Be the stupidest"],
    },
    {
        id: "C23",
        name: "Doo-bee Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C23.gltf",
        preview: "/cosmetic/preview/artifact/C23.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "C24",
        name: "Foxzy Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C24.gltf",
        preview: "/cosmetic/preview/artifact/C24.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "C25",
        name: "Ape Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C25.gltf",
        preview: "/cosmetic/preview/artifact/C25.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "C26",
        name: "Domino The Duck Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C26.gltf",
        preview: "/cosmetic/preview/artifact/C26.webp",
        obtain: ["Season 5 Zeqa Pass"],
    },
    {
        id: "C27",
        name: "Youtube Man Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C27.gltf",
        preview: "/cosmetic/preview/artifact/C27.webp",
        obtain: ["Chr7st Collaboration"],
    },
    {
        id: "C28",
        name: "Earth Spirit Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C28.gltf",
        preview: "/cosmetic/preview/artifact/C28.webp",
        obtain: ["Season 6 Login Reward"],
    },
    {
        id: "C29",
        name: "Fire Spirit Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C29.gltf",
        preview: "/cosmetic/preview/artifact/C29.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "C30",
        name: "Ice Spirit Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C30.gltf",
        preview: "/cosmetic/preview/artifact/C30.webp",
        obtain: ["Season 6 Zeqa Pass"],
    },
    {
        id: "C31",
        name: "Turtle Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C31.gltf",
        preview: "/cosmetic/preview/artifact/C31.webp",
        obtain: ["Season 7 Login Reward"],
    },
    {
        id: "C32",
        name: "MR-Crab Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C32.gltf",
        preview: "/cosmetic/preview/artifact/C32.webp",
        obtain: ["Season 7 Zeqa Pass"],
    },
    {
        id: "C33",
        name: "Pharaoh Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C33.gltf",
        preview: "/cosmetic/preview/artifact/C33.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "C34",
        name: "Frankenstein Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C34.gltf",
        preview: "/cosmetic/preview/artifact/C34.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "C35",
        name: "Bonehead Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C35.gltf",
        preview: "/cosmetic/preview/artifact/C35.webp",
        obtain: ["Halloween Bundle 2023"],
    },
    {
        id: "C36",
        name: "Farmer Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C36.gltf",
        preview: "/cosmetic/preview/artifact/C36.webp",
        obtain: ["Autumn Bundle 2023"],
    },
    {
        id: "C37",
        name: "Adventure Tom Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C37.gltf",
        preview: "/cosmetic/preview/artifact/C37.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "C38",
        name: "Batman Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C38.gltf",
        preview: "/cosmetic/preview/artifact/C38.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "C39",
        name: "Bumlebee Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C39.gltf",
        preview: "/cosmetic/preview/artifact/C39.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "C40",
        name: "Dora Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C40.gltf",
        preview: "/cosmetic/preview/artifact/C40.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "C41",
        name: "Finn Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C41.gltf",
        preview: "/cosmetic/preview/artifact/C41.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "C42",
        name: "Pokemon Ash Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C42.gltf",
        preview: "/cosmetic/preview/artifact/C42.webp",
        obtain: ["Cartoon Bundle 2023"],
    },
    {
        id: "C43",
        name: "Reindeer",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C43.gltf",
        preview: "/cosmetic/preview/artifact/C43.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "C44",
        name: "Snoopy",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C44.gltf",
        preview: "/cosmetic/preview/artifact/C44.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "C45",
        name: "Santa",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C45.gltf",
        preview: "/cosmetic/preview/artifact/C45.webp",
        obtain: ["Winter Bundle 2023"],
    },
    {
        id: "C46",
        name: "Heartseeker Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C46.gltf",
        preview: "/cosmetic/preview/artifact/C46.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "C47",
        name: "Cuddle Bear Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C47.gltf",
        preview: "/cosmetic/preview/artifact/C47.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "C48",
        name: "Cupid Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C48.gltf",
        preview: "/cosmetic/preview/artifact/C48.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "C49",
        name: "Mooberry Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C49.gltf",
        preview: "/cosmetic/preview/artifact/C49.webp",
        obtain: ["Valentines Crate 2024"],
    },
    {
        id: "C50",
        name: "Easter Bunny",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C50.gltf",
        preview: "/cosmetic/preview/artifact/C50.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "C51",
        name: "Egg Collector",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C51.gltf",
        preview: "/cosmetic/preview/artifact/C51.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "C52",
        name: "Chicken Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C52.gltf",
        preview: "/cosmetic/preview/artifact/C52.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "C53",
        name: "Stack of Chicks",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/C53.gltf",
        preview: "/cosmetic/preview/artifact/C53.webp",
        obtain: ["Easter Bundle 2024"],
    },
    {
        id: "750",
        name: "McDonalds Costume",
        type: "artifact",
        rarity: "limited",
        isTradable: false,
        content: null,
        model: "/cosmetic/model/artifact/750.gltf",
        preview: "/cosmetic/preview/artifact/750.webp",
        obtain: [],
    },
    {
        id: "9999",
        name: "PROOF OF CONCEPT 128x",
        type: "artifact",
        rarity: "limited",
        isTradable: true,
        content: null,
        model: "/cosmetic/model/artifact/9999.gltf",
        preview: "/cosmetic/preview/artifact/9999.webp",
        obtain: [],
    },
];
