import { useEffect, useState } from "react";
import { getCollection } from "../../utils/data/CosmeticData";
import CollectionRow from "./CollectionRow";

export default function Collection({ playerItem }) {
    const collections = getCollection();
    const [items, setItems] = useState();
    const [openCollection, setOpenCollection] = useState(null);

    useEffect(() => {
        if (playerItem) {
            let newItems = {};
            newItems["artifact"] = playerItem.ownedartifact.split(",");
            newItems["cape"] = playerItem.ownedcape.split(",");
            newItems["killphrase"] = playerItem.ownedkillphrase.split(",");
            newItems["projectile"] = playerItem.ownedprojectile.split(",");
            setItems(newItems);
        }
    }, [playerItem]);

    const handleCollectionClick = (collection) => {
        setOpenCollection(openCollection === collection ? null : collection);
    };

    if (collections && items) {
        return Object.keys(collections).map((collection, idx) => (
            <CollectionRow
                key={idx}
                collections={collections}
                collection={collection}
                playerItem={items}
                isOpen={openCollection === collection}
                onClick={() => handleCollectionClick(collection)}
            />
        ));
    }

    return null;
}
