import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from "react";

const items = [
    { name: "Artifacts", link: "/cosmetic/artifact", newTab: false },
    { name: "Capes", link: "/cosmetic/cape", newTab: false },
    { name: "Projectiles", link: "/cosmetic/projectile", newTab: false },
    { name: "Killphrases", link: "/cosmetic/killphrase", newTab: false },
    { name: "Profile", link: "/profile", newTab: false },
    { name: "Store", link: "https://store.zeqa.net/", newTab: true },
    { name: "Discord", link: "https://discord.com/invite/zeqa", newTab: true },
];

export default function AppNavigation() {
    const [open, setOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const handleResize = () => {
        if (window.innerWidth >= 768) {
            setOpen(false);
        }
    };

    const handleCloseMenu = useCallback(() => {
        if (open) {
            setClosing(true);
            setTimeout(() => {
                setOpen(false);
                setClosing(false);
            }, 500);
        }
    }, [open]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleCloseMenu();
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [handleCloseMenu]);

    const handleNavigation = (item) => {
        if (item.newTab) {
            window.open(item.link, '_blank');
        } else {
            navigate(item.link);
            handleCloseMenu();
        }
    };

    return (
        <div className="app_navigation_container">
            <div className="zeqa-inner-container-center">
                <div data-animation="over-left" data-collapse="medium"
                    data-duration="400" data-easing="ease-in" data-easing2="ease-out" role="banner"
                    className="zeqa-header-wrapper zeqa-nav">
                    <div className="zeqa-container-default zeqa-container">
                        <div className="zeqa-header-content-wrapper">
                            <div
                                className="zeqa-header-logo-link zeqa-nav-brand zeqa-current"
                                aria-label="home"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/");
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src="/image/zeqa_logo_transparent.webp"
                                    srcSet="/image/zeqa_logo_transparent-500.webp 500w, /image/zeqa_logo_transparent-800.webp 800w, /image/zeqa_logo_transparent-1080.webp 1080w, /image/zeqa_logo_transparent-1600.webp 1600w, /image/zeqa_logo_transparent.webp 2560w"
                                    width="124"
                                    sizes="(max-width: 479px) 104.15625px, (max-width: 767px) 111.59375px, 124px"
                                    alt="Zeqa Logo"
                                    className="zeqa-header-logo"
                                />
                            </div>

                            <div className="app_navigation_menu" ref={menuRef}>
                                <div
                                    className="zeqa-hamburger-menu-wrapper zeqa-nav-button"
                                    style={{ WebkitUserSelect: 'text' }}
                                    aria-label="menu"
                                    role="button"
                                    tabIndex="0"
                                    aria-controls="zeqa-nav-overlay"
                                    aria-haspopup="menu"
                                    aria-expanded={open ? "true" : "false"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (open) {
                                            handleCloseMenu();
                                        } else {
                                            setOpen(true);
                                        }
                                    }}
                                >
                                    <img
                                        src="http://app.zeqa.net/image/toggles/navbarfilter.png"
                                        alt="Filters"
                                        style={{ maxWidth: '47px', maxHeight: '47px', width: 'auto', height: 'auto' }}
                                    />
                                </div>
                                <div
                                    className={`app_navigation_menu_select ${open ? "open" : ""} ${closing ? "closing" : ""}`}
                                >
                                    <nav
                                        role="navigation"
                                        className="zeqa-header-menu-wrapper zeqa-nav-menu"
                                        data-nav-menu-open={open ? "" : undefined}
                                    >
                                        <ul className="zeqa-header-nav-menu-list">
                                            {items.map((item, index) => (
                                                <li key={index} className={`zeqa-header-nav-list-item ${location.pathname === item.link ? "active" : ""}`}>
                                                    <a href={item.link} className="zeqa-header-nav-link zeqa-nav-link" onClick={(e) => {
                                                        e.preventDefault();
                                                        handleNavigation(item);
                                                    }}>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
