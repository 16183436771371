import { useState, useEffect } from "react";
import { killphraseData } from "../../utils/data/KillphraseData";
import DisplaySearch from "./display/DisplaySearch";
import CosmeticCard from "./display/CosmeticCard";

export default function KillphraseDisplay() {
    const [data, setData] = useState([]);
    const [selectedRarities, setSelectedRarities] = useState([]);
    
    const rarityOrder = ["common", "rare", "epic", "legendary", "limited"];

    useEffect(() => {
        
        const sortedData = [...killphraseData].sort(
            (a, b) => rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity)
        );
        setData(sortedData);
    }, []);

    const filteredData = selectedRarities.length
        ? data.filter((item) => selectedRarities.includes(item.rarity))
        : data;

    const handleRarityChange = (e) => {
        const { value, checked } = e.target;
        setSelectedRarities((prevSelectedRarities) =>
            checked
                ? [...prevSelectedRarities, value]
                : prevSelectedRarities.filter((rarity) => rarity !== value)
        );
    };

    return (
        <div>
            <br />
            <br />
            <div className="cosmetic_containerss">
                <div className="cosmetic_container">
                    <h1 style={{ textAlign: "center" }}>Killphrases</h1>
                    <div className="cosmetic_display_header"></div>
                </div>
            </div>
            <div className="cosmetic_containers">
                <div className="cosmetic_container">
                    <div className="cosmetic_display_header">
                        <br />
                        <DisplaySearch
                            fulldata={killphraseData}
                            data={data}
                            setData={setData}
                            selectedRarities={selectedRarities}
                            handleRarityChange={handleRarityChange}
                        />
                        <h2 style={{ marginTop: "0px", marginBottom: "30px", fontSize: "24px" }}>
                            {filteredData.length} results
                        </h2>
                    </div>
                    <div className="cosmetic_display">
                        {filteredData.map((item, idx) => (
                            <CosmeticCard key={idx} item={item} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
